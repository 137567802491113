.sidebar {

    position: fixed;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    mix-blend-mode: difference;
    padding: 7.5rem 3rem 5.5rem 3.4rem;

    @media only screen and (max-width: 580px) {
        flex-direction:row ;
        height: max-content;
        width: 100vw;
        padding: 6.5rem 4.9rem 5.5rem 4.9rem;
        align-items: center;
    }

    &__border {
        height: 100%;
        border-right: .25px solid rgba($color: #E8E3D9, $alpha: .2);
        position: absolute;
        top: 0;
        left: 10rem;
        z-index: 1000 !important;

        @media only screen and (max-width: 700px) {
            display: none;
        }
    }

    &__navigation {
        // cursor: pointer;
        height: max-content;
        width: max-content;
        position: relative;
        
        &-icon {
            position: relative;
            transition: all .2s ease-in;


            &:hover {
                transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
            } 

            &
            {
                width: 1.9rem;
                height: .2rem;
                background-color: #fff;
                display: inline-block;

                @media only screen and (max-width: 580px) {
                    width: 2.4rem;
                    height: .3rem;

                }

            }

            &-t {
               
                    width: 1.9rem;
                    height: .2rem;
                    background-color: #fff;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: -.4rem;
                    transition: all .2s;

                    @media only screen and (max-width: 580px) {
                        width: 2.4rem;
                        height: .3rem;
    
                    }
    
            }

            &-b {
                position: absolute;
                    left: 0;
                width: 1.9rem;
                height: .2rem;
                background-color: #fff;
                display: inline-block;
                transition: all .2s;
                top: .4rem;

                @media only screen and (max-width: 580px) {
                    width: 2.4rem;
                    height: .3rem;

                }

            }

            // &::before,
            // &::after {
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     transition: all .2s;
            // }

            &::before { 
                top: -.4rem;
                @media only screen and (max-width: 580px) {
                    top: -.6rem;
                }
            }
            &::after { 
                top: .4rem;
                @media only screen and (max-width: 580px) {
                    top: .6rem;
                }
            }
        }
    }

    &__icon {
        fill: #fff;
        background: none;
        transition: all .3s ease-in;

        &-fb:hover{
            fill: #4267B2 !important;
        }

        &-tw:hover{
            fill: #1DA1F2 !important;
        }

        &-yt:hover{
            fill: #FF0000 !important;
        }
    }

    &__logo {
        height: 4.4rem;
        width: 4.1rem;
        // height: 4.4rem;
        // width: 11rem;
        // position: fixed;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // cursor: pointer;
        transition: all .2s ease-in;


        &:hover {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
        }
    }

    &__social {
        display: flex;
        flex-direction: column;
        row-gap: 2.7rem;
        align-items: center;

        @media only screen and (max-width: 580px) {
            display: none;
        }

        &-link {
            transition: all .2s ease-in;


            &:hover {
                transform: scale(1.2);
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -ms-transform: scale(1.2);
                -o-transform: scale(1.2);
            }
        }
       
    }

    &__btn {
        &,
        &:link,
        &:visited {
            height: max-content;
            width: max-content;
            background: transparent;
            text-decoration: none;
            outline: none;
            border: none;
        }
    }
}