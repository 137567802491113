.intro {
    height: 100vh;
    width: 100vw;
    // background-color: #000;
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;

    &__wrapper {
        height: 100vh;
        width: 100vw;
        background-color: transparent;
        position: absolute;
        top: 0;
        z-index: 5;
        display: grid;
        // grid-template-rows: 100vh repeat(7, min-content);
        grid-template-columns: [leftgutter-start] 10rem [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
        grid-gap: 1.6;
    }

    &__overlay {
        position: absolute;
        z-index: 15;
        height: 100%;
        bottom:0;
        left: 0;
        right: 0;
        background-color: #FDFDE4;
        border-right: .5px solid #E8E3D9;
        &:nth-child(1) {
            width: 100%;
            grid-column: leftgutter-start / leftgutter-end;

            background-color: #FDFDE4;
            position: absolute; 
            bottom: 0;
            z-index: 15;
          }

        &:nth-child(2) {
            width: 107.5%;
            background-color: #FDFDE4;
            position: relative;
            z-index: 15;
            grid-column: leftgutter-end / col-end 3;
            position: absolute;
            bottom: 0;
        }

        &:nth-child(3) {
            width: 97%;
            background-color:#FDFDE4;
            position: relative;
            z-index: 15;
            grid-column: col-end 3 / col-start 7;
            margin-left: 7.5%;
             position: absolute;
            bottom: 0;
        }

        &:nth-child(4) {
            width: 98.5%;
            background-color: g#FDFDE4;
            position: relative;
            z-index: 15;
            grid-column: col-start 7 / col-start 10;
            margin-left: 4.5%;
             position: absolute;
            bottom: 0;
        }

        &:nth-child(5) {
            width: 106.2%;
            background-color: pu#FDFDE4;
            position: relative;
            z-index: 15;
            grid-column: col-start 10 / full-end;
            margin-left: 2.8%;
             position: absolute;
            bottom: 0;
        }

        &:nth-child(6) {
            width: 100%;
            grid-column: rightgutter-start / rightgutter-end;

            background-color: #FDFDE4;
            position: relative; 
             position: absolute;
            bottom: 0;
        }
    }

    &__animation {
        background-color: transparent;
        position: absolute;

        height: 20rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);

        &-bg {
            height: 100vh;
            width: 100vw;
            background-color: #000;
            position: relative;
            z-index: 20;

        }
    }

    &__logoBox {
        background-color: #000;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: relative;
        // z-index: 15;
    }

    &__logo {
        fill: #fff;
        height: 4.4rem;
        width: 4.1rem;
        position: absolute;
        left: 3.4rem;
        top: 7.5rem;
        z-index: 10;

        // left: 0;
        // right: 0;
        // top: 0;
        // bottom: 0;
        // margin: auto
    }

 
}
// height: 7rem;
//         top: 5.8rem;
//         left: 3.1rem;