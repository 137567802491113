.postProduct {
    grid-column: leftgutter-start / rightgutter-end;

    display: grid;
    grid-template-columns: [leftgutter-start] minmax(6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(6rem,1fr) [rightgutter-end];
    padding-top: 7.5rem;
    background-color: #F4F2F4;
    height: max-content;
    &__wrapper {
        grid-column: full-start / full-end;

    }
}

.fetchedProducts {
    grid-column: full-start / full-end;
    margin-top: 7.5rem;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2.5rem;
}

.photoCard {
    margin-bottom: 10px;
    box-shadow: 5px 5px 10px #DDDDDD, -5px -5px 10px #FEFEFE;

    width: 100%;
    &__container {
        padding: 1rem;
    }
    
    &__name {
        margin-bottom: 3px;
        font-family: 'philosopherBold';
        color: #000000; 
        font-size: 2.4rem;
        line-height: 3rem;
    }

    &__cate {
        font-family: 'philosopherRegular';
        color: var(--color-grey-dark);
        font-size: 1.2rem;
        line-height: 2.6rem;
        letter-spacing: .15px;
    }

    &__desc {
        font-family: 'gothamLight';
        color: var(--color-black);
        font-size: 1.2rem;
        line-height: 2.6rem;
        letter-spacing: .15px;

        margin-bottom: 1rem;
    }

    &__use {
        font-family: 'azoSansLight';
        color: #4B4B47;
        font-size: 1.2rem;
        line-height: 2.6rem;
    }

    &__image {
        width: 100%;
        height: 20rem;
        object-fit: contain;
        margin-bottom: 2rem;
    }

    &__btnGroup{
        display: flex;
        justify-content: space-around;
    }
}

