*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

//RESPONSIVE BREAKPOINTS
$bp-giant: 87.5em; //1400px
$bp-huge: 81.25em; //1300px
$bp-largest: 75em; //1200px
$bp-semiLarge: 68.75em; //1100px

$bp-large: 62.5em; //1000px
$bp-medium: 56.25em; //900px
$bp-small: 43.75em; //700px
$bp-tiny: 31.25em; //500px

html {
    box-sizing: border-box;
    font-size: 62.5%; //10px/16px = 62.5% (1rem = 10px)
    // scroll-behavior: smooth;
    @media only screen and (max-width: $bp-medium) {
        font-size: 50%;
    }
}



.container {
    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    overflow-x: hidden;
  
    
}

.displayNone {
    display: none !important;
    visibility: hidden;
    opacity: 0;
}

.cursor div{
    mix-blend-mode: difference;
    
}