@font-face {
    font-family: 'gothamBook';
    src: url('../../assets/font/GothamBook.ttf') format('truetype');
}

@font-face {
    font-family: 'gothamLight';
    src: url('../../assets/font/GothamLight.ttf') format('truetype');
}

@font-face {
    font-family: 'philosopherBold';
    src: url('../../assets/font/PhilosopherBold.ttf') format('truetype');
}

@font-face {
    font-family: 'philosopherRegular';
    src: url('../../assets/font/PhilosopherRegular.ttf') format('truetype');
}


.heading-large {

    &--white {
        font-family: 'philosopherBold';
        font-size: 10.1rem;
        line-height: 11rem;
        // letter-spacing: 4px;
        color: #fff;

        @media only screen and (max-width: 580px) {
            font-size: 7rem;
        }

        @media only screen and (max-width: 450px) {
            font-size: 5rem;
    
        }
    }

    &--black {
        font-family: 'philosopherBold';
        font-size: 10.1rem;
        line-height: 11rem;
        letter-spacing: 4px;
        // color: #050505;

        @media only screen and (max-width: 580px) {
            font-size: 7rem;
        }

        @media only screen and (max-width: 450px) {
            font-size: 5rem;
    
        }
    }
}

.heading-small {
    font-family: 'philosopherBold';
    font-size: 2.4rem;
    line-height: 2.7rem;
    letter-spacing: 4px;
    color: #fff;
}

.heading-tiny {
    font-family: 'gothamLight';
    font-family: 1.2rem;
    letter-spacing: 2px;
    line-height: 1.8rem ;
    color: #fff;

    &--bl {
        font-family: 'gothamLight';
        font-family: 1.2rem;
        letter-spacing: 2px;
        line-height: 1.8rem ;
        color: #050505;
    }
}

.admin__heading {
    &-main {
        display: flex;
        align-items: center;
       
        &--anim {
            width: max-content;
            transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            -moz-transition: all .2s ease-in;
            -ms-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;

            &:hover{
                color: #fff ;
            }

        }
    }

    &-sub {
        display: block;
        margin-left: 5rem;

        &--anim {
            width: max-content;
            transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            -moz-transition: all .2s ease-in;
            -ms-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;

            &:hover{
                color: #fff ;
            }

        }

        @media only screen and (max-width: 580px) {
            margin-top: -4rem;
        }
        
        @media only screen and (max-width: 480px) {
            margin-left: 5rem;

        }
    }

}

.admin-large--heading {
        font-family: 'philosopherBold';
        font-size: 5.1rem;
        line-height: 5rem;
        // letter-spacing: 4px;
        color: #000;
        margin-top: 2rem;
        margin-bottom: 3.5rem;

        @media only screen and (max-width: 580px) {
            font-size: 7rem;
        }

        @media only screen and (max-width: 450px) {
            font-size: 5rem;
    
        }
}