.category {
    grid-column: leftgutter-start / rightgutter-end;
    background-color: #FFFDF7;
    height: 100vh;
    width: 100vw;

    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    overflow-x: hidden;

    @media only screen and (max-width: 450px) {
        grid-template-columns: [leftgutter-start] minmax(3.5rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.5rem,1fr) [rightgutter-end];

    }

    &__container {
        grid-column: full-start / full-end;
        padding: 10.25rem 0 8.5rem 0;
        height: 100vh;
        position: relative;

        @media only screen and (max-width: 580px) {
            padding: 12.45rem 0 7.7rem 0;

        }

        @media only screen and (max-width: 450px) {
            padding: 12.45rem 3rem 7.7rem 3rem;
    
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        column-gap: 2.6rem;
        height: 100%;

        @media only screen and (max-width: 580px) {
            flex-direction:column ;
            row-gap: 1.4rem;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        position: relative;
        // cursor: pointer;
        overflow: hidden;
        // &:hover .category__title {
        //     opacity: 1;
        //     transform: translate(-50%, -50%);
        //     -webkit-transform: translate(-50%, -50%);
        //     -moz-transform: translate(-50%, -50%);
        //     -ms-transform: translate(-50%, -50%);
        //     -o-transform: translate(-50%, -50%);
        // }

        &:hover .category__img {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            mix-blend-mode: normal;

}
  
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
        mix-blend-mode: luminosity;
        position: relative;
        transition: all .3s ease-in;
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
        -webkit-transition: all .3s ease-in;
        -moz-transition: all .3s ease-in;
        -ms-transition: all .3s ease-in;
        -o-transition: all .3s ease-in;
}

    &__overlay {
        height: 100%;
        width: 100%;
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .2;
    }
    
    &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        // opacity: 0;
        transition: all .3s ease;
        transform: translate(-50%, -20%);
        -webkit-transform: translate(-50%, -20%);
        -moz-transform: translate(-50%, -20%);
        -ms-transform: translate(-50%, -20%);
        -o-transform: translate(-50%, -20%);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
    }

    

    &__mark {
        color: #050505;
        position: absolute;
        top: 6.45rem;
        left: 9.15rem;

        @media only screen and (max-width: 580px) {
            display: none;
        }
    }
}