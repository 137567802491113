.header {
    grid-column: leftgutter-start / rightgutter-end;

    height: 100vh;
    width: 100vw;

    // background-image: url(../../assets/img/headerBg.png);
    background-size: cover;
    background-position: left ;

    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;
    // @media only screen and (max-width: 1000px) {
    //     background-position: center  ;
    // }

    // @media only screen and (max-width: 700px) {
    //     background-position: right  ;
    // }

    @media only screen and (max-width: 450px) {
        grid-template-columns: [leftgutter-start] minmax(3.5rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.5rem,1fr) [rightgutter-end];

    }

    &__bgImg {
        grid-column: leftgutter-start / rightgutter-end;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1 !important;
        @media only screen and (max-width: 1000px) {
            object-position: center  ;
        }
    
        @media only screen and (max-width: 700px) {
            object-position: right  ;
        }
    }

    &__container {
        grid-column: col-start 2/ full-end;
        
        // margin-left: 9.2rem;
        padding-top: 20.9rem;
        padding-bottom: 8.15rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        
        &-resp {
            @media only screen and (max-width: 580px) {
                padding-top: 0 !important; 
                padding-bottom: 0 !important;
                justify-content: center;

            }
        }
    }

    &__heading {
        &-main {
            display: flex;
            align-items: center;
           
            &--anim {
                width: max-content;
                transition: all .2s ease-in;
                -webkit-transition: all .2s ease-in;
                -moz-transition: all .2s ease-in;
                -ms-transition: all .2s ease-in;
                -o-transition: all .2s ease-in;

                &:hover{
                    color: #fff ;
                }

            }
        }

        &-sub {
            display: block;
            margin-left: 10rem;

            &--anim {
                width: max-content;
                transition: all .2s ease-in;
                -webkit-transition: all .2s ease-in;
                -moz-transition: all .2s ease-in;
                -ms-transition: all .2s ease-in;
                -o-transition: all .2s ease-in;

                &:hover{
                    color: #fff ;
                }

            }

            @media only screen and (max-width: 580px) {
                margin-top: -4rem;
            }
            
            @media only screen and (max-width: 480px) {
                margin-left: 5rem;

            }
        }

    }

    &__btnGroup {
        display: flex;
        column-gap: 5rem;
        align-items: center;
        justify-content: flex-start;
        height: max-content;
        // margin-top: 20.3rem;
        // margin-bottom: 81.5rem;

        &-resp{
            @media only screen and (max-width: 580px) {
                display: none;
            }
        }
    }

    &__mark {
        position: absolute;
        right: -5.5rem;
        bottom: 13.15rem;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);

        @media only screen and (max-width: 580px) {
            display: none;
        }
    }

    &__heading {
        color: #000;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
    }

    &__dot {
    
        padding-bottom: 6%;
        padding-right: 1rem;
    }

//     &__letters {
//         transition: all .2s ease-in;
//         -webkit-transition: all .2s ease-in;
//         -moz-transition: all .2s ease-in;
//         -ms-transition: all .2s ease-in;
//         -o-transition: all .2s ease-in;
//         color: #000;
//         // &:hover {
//         //     color: #fff;
//         // }
// }
   
}