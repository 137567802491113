.adminHome {
    grid-column: leftgutter-start / rightgutter-end;

    
    &__container {
        display: grid;
        grid-template-columns: [leftgutter-start] minmax(6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(6rem,1fr) [rightgutter-end];
        height: 100vh;
    }

    &__navigation {
        grid-column: leftgutter-start / col-start 2;
        padding-left: 2rem;
        background-color: var(--color-grey-light);

        &-wrapper {
            display: flex;
            flex-direction: column;
        }

        &-item {
            &,
            &:link,
            &:visited {
              font-family: 'gothamMedium';
              line-height: 2rem;
              font-size: 1.4rem;
              text-decoration: none;
              text-transform: uppercase;
              color: #707070;
              padding: 15px;
          
              // border-bottom: 2px solid var(--color-primary);
              letter-spacing: 2px;
          
            }
          
            &:active,
            &:focus {
                outline: none;
            }
        }

    }

    &__content {
        grid-column: col-start 2 / rightgutter-end;
        height: 100%;
        overflow-y: scroll;
        padding: 3rem;

    }

    &__card {
        background-color: transparent;
        border: 2px solid #F5F5F5;
        height: max-content;
        width: 100%;
        // width: 28.5rem;
        padding: 1.5rem 1.6rem 2.7rem 1.6rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin: 1rem;
        margin-bottom: 5.5rem;
        // align-items: flex-start;
        justify-content: space-between;

        transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -ms-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;

        &:hover {
            box-shadow: 2px 4px 4px rgba(#000000, .1);
            border: 2px solid transparent;

        }

    }
}



// .statusBar {
//     width: 100%;
//     position: absolute;
//     height: 500px;
//     background-color: khaki;
// }
