.navigation {
    grid-column: leftgutter-start / rightgutter-end;

    height: 100vh;
    width: 100vw;

    background-image: url(../../assets/img/navBg.png);
    background-size: cover;
    background-position: left ;

    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;
    
    @media only screen and (max-width: 450px) {
        grid-template-columns: [leftgutter-start] minmax(3.5rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.5rem,1fr) [rightgutter-end];

    }

    &::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: .55;
        z-index: 2;
    }

    &__container {
        grid-column: col-start 2 / full-end;

        // margin-left: 9.2rem;
        height: 100%;
        position: relative;
        z-index: 3;
    }

    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__links {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
    }

    &__link {
        &,
        &:link,
        &:visited {
            background: transparent;
            text-decoration: none;
            border: none;
            outline: none;
            color: #A1A1A1;
            font-family: 'philosopherRegular';
            font-size: 7rem;
            line-height: 7.6rem;
            letter-spacing: 3px;
            // cursor: pointer;
            transition:  color .2s ease, font-family .2s ease;
            -webkit-transition:  color .2s ease, font-family .2s ease;
            -moz-transition:  color .2s ease, font-family .2s ease;
            -ms-transition:  color .2s ease, font-family .2s ease;
            -o-transition:  color .2s ease, font-family .2s ease;

            @media only screen and (max-width: 580px) {
                font-size: 5rem;
                line-height: 5.6rem;

            }
        }

        &:hover {
            color: #F7F4ED;
            font-family: 'philosopherBold';
        }

        &:active,
        &:focus {
            outline: none;
        }
    }

    &__btnGroup {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        row-gap: 2rem;
        
        @media only screen and (max-width: 580px) {
            display: none;
        }
    }
    
}

.flexSpacer {
    height: 7rem;
}