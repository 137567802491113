.brand {
    grid-column: leftgutter-start / rightgutter-end;

    height: 100vh;
    width: 100vw;
    background-color: #FFFDF7;


    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 450px) {
        grid-template-columns: [leftgutter-start] minmax(3.5rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.5rem,1fr) [rightgutter-end];

    }

    &__container {
        grid-column: col-start 2 / full-end;

        // margin-left: 9.2rem;
       
        height: 100%;
        display: flex;
        position: relative;
        z-index: 3;


        @media only screen and (max-width: 580px) {
            flex-direction: column;
            margin-left: 0;
            align-items: center;

        }
    }

    &__wrapper {
        padding-top: 20.9rem;
        padding-bottom: 8.15rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__slider {
        height: 100%;
        width: 100%;
    //    margin-top: 5rem;
       display: flex;
       justify-content: center;
       align-items: flex-end;
       margin-top: 3rem;
      
       
        &-img {
            height: 15rem;
            width: 15rem;
            object-fit: contain;

            @media only screen and (max-width: 580px) {
                height: 10.5rem;
                width: 10.5rem;
    
            }
        }

        & .swiper-container {
            height: 70rem;
        }

    }

    &__btnGroup {
        display: none !important;

        @media only screen and (max-width: 580px) {
            position: absolute;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            width: 100%;
            top: 72rem;
            z-index: 999;
        }

        &-resp{
            @media only screen and (max-width: 580px) {
                display: none !important;
    
            }
        }
    }
}

