.cta {

    &,
    &:link,
    &:visited {
        background: transparent;
        text-decoration: none;
        border: none;
        outline: none;
        color: #fff;
        font-family: 'gothamBook';
        font-size: 1rem;
        line-height: 1.8rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 1.5rem 3.6rem;
        border: .75px solid #FFFFFF;
        // cursor: pointer;
        transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -ms-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
}

    &:hover {
        background-color: #FFFFFF;
        color: #040404;
    }

    &:active,
    &:focus {
        outline: none;
    }

   
}

.cta-bl {
    
    &,
    &:link,
    &:visited {
        background: transparent;
        text-decoration: none;
        border: none;
        outline: none;
        color: #050505;
        font-family: 'gothamBook';
        font-size: 1rem;
        line-height: 1.8rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 1.5rem 3.6rem;
        border: .75px solid #050505;
        // cursor: pointer;
        transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -ms-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
}

    &:hover {
        background-color: #040404;
        color: #fff;
    }

    &:active,
    &:focus {
        outline: none;
    }
}

.delete__btn {
    text-align: center !important;
      &,
    &:link,
    &:visited{
      font-family: 'philosopherRegular';
      line-height: 2rem;
      font-size: 1.4rem;
      text-decoration: none;
      text-transform: uppercase;
      color: #A8A8A8;
      border-bottom: 2px solid #A8A8A8;
      padding-bottom: 4.5px;
      letter-spacing: 2px;
      display: inline-block;
      transition: all .3s;
    }
  
    &:active,
    &:focus {
        outline: none;
    }

    &:hover {
      
      transform: translateY(-.3rem);
    }
  }

  .edit__btn {
    text-align: center !important;
      &,
    &:link,
    &:visited{
      font-family: 'philosopherRegular';
      line-height: 2rem;
      font-size: 1.4rem;
      text-decoration: none;
      text-transform: uppercase;
      color: #A8A8A8;
      border-bottom: 2px solid #A8A8A8;
      padding-bottom: 4.5px;
      letter-spacing: 2px;
      display: inline-block;
      transition: all .3s;
    }
  
    &:active,
    &:focus {
        outline: none;
    }

    &:hover {
      
      transform: translateY(-.3rem);
    }
  }

  
  .btn__admin {
    &,
  &:link,
  &:visited {
    font-family: 'poppinsSemiBold';

    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    padding: 15px;
    // border-bottom: 2px solid #4B4B47;
    letter-spacing: 2px;
    background-color: #000;
    border: none;
    cursor: pointer;

  }

  &:active,
  &:focus {
      outline: none;
  }

  transition: all .3s;

    &:hover {
        background-color: var(--color-primary);
        color: #000;

    }

    &Wrapper {
      display: flex;
      justify-content: left;
      align-items: left;
    }
  }