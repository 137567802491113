.home {

    &__pagination {
            color: #fff;
            z-index: 999;
            height: 3px;
            width: 3px;
            border-radius: 100%;
            background-color: #F7F4ED;
            // cursor: pointer;

        &-active {
            height: 7px;
            width: 7px;
        }

        &-wrapper {
            color: #fff;
            position: fixed;
            right: 4.25rem;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            height: 100%;
            align-items: center;
            justify-content: center;
            mix-blend-mode: difference;
        }
    }

    &__stamp {
        position: absolute;
        top: -4rem;
        right: 10rem;
        mix-blend-mode: difference;
        transition: all .3s ease-in;

        &> svg {
            fill: #fff;
        }

        @media only screen and (max-width: 700px) {
            display: none;
        }
    }

    &__social-resp {
        position: fixed;
        bottom: 3.4rem;
        display: none;
        column-gap: 2.7rem;
        align-items: center;
        mix-blend-mode: difference;
        width: 100%;
        justify-content: center;

        @media only screen and (max-width: 580px) {
            display: flex;
        }

    }

    &__grid1 {
        height: 100%;
        width: .25px;
        background-color: rgba($color: #E8E3D9, $alpha: .2);

        z-index: 999;
        grid-column: col-start 4 / col-start 4;
        margin-left: 2px;
        @media only screen and (max-width: 700px) {
            display: none;
        }
    }

    &__grid2 {
        height: 100%;
        width: .25px;
        background-color: rgba($color: #E8E3D9, $alpha: .2);

        z-index: 999;
        grid-column: col-start 7 / col-start 7;
        // margin-left: 2px;
        @media only screen and (max-width: 700px) {
            display: none;
        }
    }

    
    &__grid3 {
        height: 100%;
        width: .25px;
        background-color: rgba($color: #E8E3D9, $alpha: .2);

        z-index: 999;
        grid-column: col-start 10 / col-end 10;
        margin-left: 1px;
        @media only screen and (max-width: 700px) {
            display: none;
        }
    }

    &__grid4 {
        height: 100%;
        border-right: .25px solid rgba($color: #E8E3D9, $alpha: .2);
        position: absolute;
        top: 0;
        right: 10rem;
        z-index: 1000 !important;
        @media only screen and (max-width: 700px) {
            display: none;
        }
    }
}

.hr {

    &-1 {
        height: 100vh;
        border-left: .25px solid #E8E3D9 ;
        position: fixed;
        left: 6%;
    }

    &-2 {
        height: 100vh;
        border-left: .25px solid #E8E3D9 ;
        position: fixed;
        left: 26%;
    }

    &-3 {
        height: 100vh;
        border-left: .25px solid #E8E3D9 ;
        position: fixed;
        left: 50%;
    }

    &-4 {
        height: 100vh;
        border-left: .25px solid #E8E3D9 ;
        position: fixed;
        right: 26%;
        z-index: 3;
    }

    &-5 {
        height: 100vh;
        border-left: .25px solid #E8E3D9 ;
        position: fixed;
        right: 6%;
        z-index: 3;
    }

 
}