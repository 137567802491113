.merch {
    grid-column: leftgutter-start / rightgutter-end;

    height: 100vh;
    width: 100vw;

    // background-image: url(../../assets/img/merchBg.png);
    // background-size: cover;
    // background-position: left ;

    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;
    
    // @media only screen and (max-width: 580px) {
    //     background-position: top ;

    // }

    @media only screen and (max-width: 450px) {
        grid-template-columns: [leftgutter-start] minmax(3.5rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.5rem,1fr) [rightgutter-end];

    }

    &__bgImg {
        grid-column: leftgutter-start / rightgutter-end;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: left;
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1 !important;
        @media only screen and (max-width: 580px) {
            object-position: top  ;
        }
    
        // @media only screen and (max-width: 700px) {
        //     object-position: right  ;
        // }
    }

    &__container {
        grid-column: col-start 2 / full-end;

        // margin-left: 9.2rem;
        padding-top: 20.9rem;
        padding-bottom: 8.15rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @media only screen and (max-width: 580px) {
            padding-top: 60%;
            padding-bottom: 18.15rem;
            margin-left: 0;
            align-items: center;
            justify-content: center;

        }
    
    }

    &__heading {
        @media only screen and (max-width: 580px) {
            margin-bottom: 16.2rem;
            margin-top: 10rem;
        }
    }
}